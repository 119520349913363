import React, { useState, useRef, useEffect } from 'react'
import Hashtag from '../PropsComponents/hashtag'
import TitleColors from '../PropsComponents/titleColors'
import Biggest from '../../assets/img/LatestVideos/biggest.jpg'
import Up from '../../assets/img/LatestVideos/up.jpg'
import Down from '../../assets/img/LatestVideos/down.jpg'
import { FaPlay } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import { useIntl } from 'react-intl';

export default function HomeVideos() {
  const [Open, setOpen] = useState(true)
  const [Open1, setOpen1] = useState(true)
  const [Open2, setOpen2] = useState(true)

  const intl = useIntl();

  const hashtags = [{
    text_am: "Վիդեոներ", text_ru: "Видео", text_en: "Videos"
  }]

  const titles = [{
    black_en: "My latest videos", black_ru: "Мои последние видео", black_am: "Իմ վերջին վիդեոները"
  }]

  return (
    <>
      <div id='Videos' className='mt-32'>
        <div className='flex flex-col justify-center items-center'>
          {
            hashtags.map((item, index) => {
              return <Hashtag text={item[`text_${intl.locale}`]} key={index}/>
            })
          }
          {
            titles.map((item, index) => {
              return <TitleColors black={item[`black_${intl.locale}`]} blue={'.'} key={index}/>
            })
          }
        </div>
      </div>

      <div className='flex flex-col max-lg:space-y-5 justify-center items-center lg:flex md:justify-center lg:space-x-5 lg:flex-row  mt-16'>
        <div className='w-[80%] md:w-[80%] md:space-y-5 h-1/3 lg:w-[60%] relative lg:h-full rounded-2xl overflow-hidden '>
          <img
            src={Biggest}
            className='object-cover w-[100%] h-[100%] hover:scale-110 duration-300'
            alt=''
          />

          <div
            className={
              Open
                ? 'hidden'
                : 'visible w-full h-[100vh] !z-[100] bg-white/20 flex flex-col justify-center items-center fixed top-0 left-0'
            }
          >
            <div className='w-[90%] sm:w-[560px] text-end text-red-500 opacity-100  text-[32px]'>
              <button>
                <AiOutlineClose
                  onClick={() => {
                    setOpen(!Open)
                  }}
                />
              </button>
            </div>
            <iframe
              width=''
              height='315'
              src='https://www.youtube.com/embed/MLpWrANjFbI'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
              className='relative !z-50 w-[90%] sm:w-[560px]'
            ></iframe>
          </div>
          <div className='bg-white hover:cursor-pointer px-6 py-3 absolute shadow-3xl rounded-2xl z-50 top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4'>
            <FaPlay
              className=' text-main-blue dark:text-main-pink text-2xl '
              onClick={() => {
                setOpen(!Open)
              }}
            />
          </div>
        </div>

        <div className='h-[30%] w-[80%] space-y-5 flex-row items-center justify-center lg:w-[30%] lg:space-y-6 lg:h-full overflow-hidden lg:flex lg:flex-col'>
          <div className='rounded-2xl h-1/3  lg:h-[203px] overflow-hidden relative'>
            <img
              src={Up}
              className='object-cover relative z-0 w-full h-full hover:scale-110 duration-300'
              alt=''
            />

            <div
              className={
                Open1
                  ? 'hidden'
                  : 'visible w-full h-[100vh] !z-[100] bg-white/20 flex flex-col justify-center items-center fixed top-0 left-0'
              }
            >
              <div className='w-[90%] sm:w-[560px] text-end text-red-500 opacity-100  text-[32px]'>
                <button>
                  <AiOutlineClose
                    onClick={() => {
                      setOpen1(!Open1)
                    }}
                  />
                </button>
              </div>
              <iframe
                width=''
                height='315'
                src='https://www.youtube.com/embed/MLpWrANjFbI'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
                className='relative !z-50 w-[90%] sm:w-[560px]'
              ></iframe>
            </div>
            <div className='bg-white hover:cursor-pointer px-6 py-3 absolute shadow-3xl rounded-2xl z-50 top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4'>
              <FaPlay
                className=' text-main-blue dark:text-main-pink text-2xl '
                onClick={() => {
                  setOpen1(!Open1)
                }}
              />
            </div>
          </div>

          <div className='rounded-2xl relative h-1/3 lg:h-[203px] overflow-hidden'>
            <img
              src={Down}
              className='object-cover w-full h-full hover:scale-110 duration-300'
              alt=''
            />

            <div
              className={
                Open2
                  ? 'hidden'
                  : 'visible w-full h-[100vh] !z-[100] bg-white/20 flex flex-col justify-center items-center fixed top-0 left-0'
              }
            >
              <div className='w-[90%] sm:w-[560px] text-end text-red-500 opacity-100  text-[32px]'>
                <button>
                  <AiOutlineClose
                    onClick={() => {
                      setOpen2(!Open2)
                    }}
                  />
                </button>
              </div>
              <iframe
                height='315'
                src='https://www.youtube.com/embed/MLpWrANjFbI'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
                className='relative !z-50 max-sm:!w-[90%] !w-[560px]'
              ></iframe>
            </div>
            <div className='bg-white hover:cursor-pointer px-6 py-3 absolute shadow-3xl rounded-2xl z-50 top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4'>
              <FaPlay
                className=' text-main-blue dark:text-main-pink text-2xl '
                onClick={() => {
                  setOpen2(!Open2)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
