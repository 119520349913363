import React from "react";


export default function TitleColors(props){
    return(
        <>
            <div className="text-[20px] sm:text-5xl font-bold">
                {props.black}<span className="text-main-blue dark:text-main-pink sm:text-5xl">{props.blue}</span>
            </div>
        </>
    )
}