import React from "react";
import ReviewGirl from "../../assets/img/Reviews/Review.jpg"
import Hashtag from "../PropsComponents/hashtag";
import TitleColors from "../PropsComponents/titleColors";
import { FaQuoteRight } from "react-icons/fa";
import Rupr from "../../assets/img/rupr.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderReviews from "../PropsComponents/sliderReviews";
import Slider from "react-slick";
import { useIntl } from 'react-intl';


export default function HomeReviews() {

    const intl = useIntl();

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,


    };


    const slide = [
        { name_en: 'John Doe', 
          name_ru: 'Джон Дое',
          name_am: 'Ջոն Դոու',
          designation_en: 'Designer', 
          designation_ru: 'Дизайнер', 
          designation_am: 'Դիզայներ', 
          desc_en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
          desc_ru: 'Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.', 
          desc_am: 'Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։' 
        },

        { name_en: 'John Doe', 
        name_ru: 'Джон Дое',
        name_am: 'Ջոն Դոու',
        designation_en: 'Designer', 
        designation_ru: 'Дизайнер', 
        designation_am: 'Դիզայներ', 
        desc_en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
        desc_ru: 'Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.', 
        desc_am: 'Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։' },

        { name_en: 'John Doe', 
        name_ru: 'Джон Дое',
        name_am: 'Ջոն Դոու',
        designation_en: 'Designer', 
        designation_ru: 'Дизайнер', 
        designation_am: 'Դիզայներ', 
        desc_en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
        desc_ru: 'Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.', 
        desc_am: 'Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։'},

        { name_en: 'John Doe', 
        name_ru: 'Джон Дое',
        name_am: 'Ջոն Դոու',
        designation_en: 'Designer', 
        designation_ru: 'Дизайнер', 
        designation_am: 'Դիզայներ', 
        desc_en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
        desc_ru: 'Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.', 
        desc_am: 'Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։'},

        { name_en: 'John Doe', 
        name_ru: 'Джон Дое',
        name_am: 'Ջոն Դոու',
        designation_en: 'Designer', 
        designation_ru: 'Дизайнер', 
        designation_am: 'Դիզայներ', 
        desc_en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 
        desc_ru: 'Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.', 
        desc_am: 'Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։'},

    ]


    const hashtags = [{
        text_am: "Կարծիքներ", text_ru: "Օбзоры", text_en: "Reviews"
    }]


    const titles = [{
        black_en: "What does our Subscriber say", black_ru: "Что говорят наши Подписчики", black_am: "Ինչ են ասում մեր Հետևորդները"
    }]


    return (
        <>

            <div id="Reviews" className="w-full flex flex-col-reverse lg:flex lg:flex-row   lg:mt-36 for_image">
                <div className="w-full lg:w-1/2 relative">
                    <div className="w-[90%] h-[400px] md:h-[500px] lg:h-[650px] duration-700 overflow-hidden mx-auto  rounded-3xl">
                        <img src={ReviewGirl} className="h-full w-full object-cover" alt="" />
                    </div>
                    <div className="absolute top-[45%] hover:animate-btn text-white right-0 bg-main-blue dark:bg-main-pink rounded-3xl p-[30px] text-4xl">
                        <FaQuoteRight />
                    </div>
                </div>
                <div className="w-full relative lg:w-1/2">
                    <div className="absolute h-12 w-24 top-10 right-10 animate-beat ">
                        <img src={Rupr} alt="" />
                    </div>
                    <div className="w-[100%] mx-auto pl-10 space-y-5 py-28">
                        {
                            hashtags.map((item, index) => {
                                return <Hashtag text={item[`text_${intl.locale}`]} key={index}/>
                            })
                        }
                        {
                            titles.map((item, index) => {
                                return <TitleColors black={item[`black_${intl.locale}`]} blue={'.'} key={index}/>
                            })
                        }

                        <Slider {...settings}>
                            {

                                slide && slide.map((item, index) => {
                                    return <SliderReviews name={item[`name_${intl.locale}`]} designation={item[`designation_${intl.locale}`]} desc={item[`desc_${intl.locale}`]} key={index}/>

                                })
                            }
                        </Slider>


                    </div>



                </div>

            </div >

        </>
    )
}