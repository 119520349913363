import MiniTitles from "./miniTitles";
import Description from "./description"
import MoreButton from "./moreButton"
import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai"



export default function NewsContent(props){
    return(
        <>
        <div className="w-full lg:w-1/3 max-lg:mt-5">
            <div className="w-[90%] mx-auto overflow-hidden border shadow-analitics  rounded-3xl">
                <div className="hover:cursor-pointer">
                  <img src={props.imagenews} alt="" />
                </div>
                <div className="space-y-5 p-6">
                    <MiniTitles mini={props.newstitle}/>
                    <p className="flex items-center font-lato text-[12px] space-x-2 text-[#FEC14A] dark:text-main-pink"><AiFillClockCircle/>  {props.newsdata}</p>
                    <Description text={props.newstext}/>

                    <MoreButton btn={props.newsbutton} icon={<AiFillCaretRight/>}/>

                </div>

            </div>

        </div>
        
        
        </>
    )
}