import React from "react"
import { useState, useEffect } from "react";
import {MdDarkMode , MdOutlineLightMode} from 'react-icons/md';

export default function ThemeSwitch() {
    const [theme, setTheme] = useState(null);

    useEffect(() => {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setTheme('dark');
        }
        else {
            setTheme('light');
        }
    }, [])

    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    const handleThemeSwitch = () => {
        setTheme(theme === "dark" ? "light" : "dark");
    };
    return (
        <>
            <button className="cursor-pointer z-10 w-12 h-12 text-[24px] flex justify-center items-center rounded-full border-[1px] border-mainBlue dark:border-main-pink fixed right-8 top-24 duration-200" onClick={handleThemeSwitch}>
            {
                theme === "dark" ? <MdOutlineLightMode className="text-main-pink" /> : <MdDarkMode className="text-main-blue" />
            }
            </button>
        </>
    )
}