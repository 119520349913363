import React from "react";
import HomeFirst from "./Components/homeFirst";
import HomeAbout from "./Components/homeAbout";
import HomeServices from "./Components/homeServices";
import HomeVideos from "./Components/homeVideos";
import HomeReviews from "./Components/homeReviews";
import HomeNews from "./Components/homeNews";
import HomeContact from "./Components/homeContact";





export default function Home() {

    return(
        <>
            <HomeFirst />
            <HomeAbout />
            <HomeServices />
            <HomeVideos />
            <HomeReviews />
            <HomeNews />
            <HomeContact />
        </>
    )
}