import React from "react";
import Logo from "../../assets/img/Logo/Logo.png";
import ForAbout from "../PropsComponents/forAbout";
import Description from "../PropsComponents/description";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { AiFillCaretRight } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { HiMail } from "react-icons/hi"
import MiniTitles from "../PropsComponents/miniTitles";
import { useIntl } from 'react-intl';


export default function Footer() {

    const Icons = [
        { icon: <BsFacebook /> },
        { icon: <BsTwitter /> },
        { icon: <BsYoutube /> },
    ]


    const intl = useIntl()

    const footerdesc = [
        {
            footerdesc_am: "Հաճախորդը շատ կարևոր է, հաճախորդին հետևելու է հաճախորդը։ Ինչպես երկրի երկիրը, ողբը, ոչ էլ կապրալը, առյուծի բարձը:",
            footerdesc_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
            footerdesc_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        }
    ]

    const firsttitle = [{

        address: <FaMapMarkerAlt />,

        firsttitle_en: "Navigations",
        firsttitle_ru: "Навигации",
        firsttitle_am: "Նավիգացիաներ",
    }]
    const firstul = [
        {
            firstul_am: "ՀՏՀ-ներ",
            firstul_ru: "ЧЗВ",
            firstul_en: "FAQ's",
            
        },
        {
            firstul_am: "Գաղտնիության քաղաքականություն",
            firstul_ru: "политика конфиденциальности  ",
            firstul_en: "Privacy Policy",

        },
        {
            firstul_am: "Ժամկետը և պայմանները",
            firstul_ru: "Срок и условия",
            firstul_en: "Term & Conditions",

        },
        {
            firstul_am: "Կապ",
            firstul_ru: "Контакт",
            firstul_en: "Contact",

        },
    ]

    const secondtitle = [{


        


        secondtitle_en: "Resource",
        secondtitle_ru: "Ресурс",
        secondtitle_am: "Ռեսուրս",
    }]
    const secondul = [
        {
            secondul_am: "Բաց Աղբյուր",
            secondul_ru: "Открытый Источник",
            secondul_en: "Open Source",

        },
        {
            secondul_am: "Փաստաթղթեր",
            secondul_ru: "Документация ",
            secondul_en: "Documentation",

        },
        {
            secondul_am: "Օգտվողի Ստուդիա",
            secondul_ru: "Пользовательская Студия",
            secondul_en: "User Studio",

        },
        {
            secondul_am: "Ուղիղ եթեր",
            secondul_ru: "Живая сессия",
            secondul_en: "Live Seassion",

        },
    ]
    const thirdtitle = [{
        thirdtitle_en: "Contact ",
        thirdtitle_ru: "Контакт",
        thirdtitle_am: "Կոնտակտ",
    }]
    const thirdul = [
        {

            address: <FaMapMarkerAlt />,

            thirdul_am: "4096 Մոդեստո, Կալիֆորնիա 95350, ԱՄՆ",
            thirdul_ru: "4096 Модесто, Калифорния 95350, США",
            thirdul_en: "4096 Modesto, CA 95350, USA",

        },
        {
            address: <IoIosCall />,
            thirdul_am: "+12094728764",
            thirdul_ru: "+12094728764 ",
            thirdul_en: "+12094728764",

        },
        {
            address: <HiMail />,
            thirdul_am: "Օգտվողի Ստուդիա",
            thirdul_ru: "Пользовательская Студия",
            thirdul_en: "User Studio",

        },

    ]

    return (
        <>
            <div id="ContactUs" className="mt-36 pb-20 border-b border-main-blue dark:border-main-pink flex flex-wrap lg:flex lg:flex-row ">

                <div className="w-full sm:w-1/2  lg:w-2/5  space-y-8 max-lg:px-5">
                    <div className="h-8 w-32">
                        <img src={Logo} className="h-full w-full" alt="" />
                    </div>
                    <div className="mt-5">
                        {
                            footerdesc.map((item, index) => {
                                return <Description text={item[`footerdesc_${intl.locale}`]} key={index}/>
                            })
                        }
                    </div>
                    <div className="w-fit  flex items-center">
                        {
                            Icons && Icons.map((item, index) => {
                                return <ForAbout icon={item.icon} key={index}/>
                            })
                        }
                    </div>
                </div>

                <div className="w-full sm:w-1/2 lg:w-1/5 space-y-8 max-lg:px-5">
                    {firsttitle.map((item, index) => {
                        return <MiniTitles mini={item[`firsttitle_${intl.locale}`]} key={index}/>
                    })}

                    <ul className="space-y-2">
                        {firstul.map((item, index) => {
                            return <li className="flex items-center" key={index}><span className="text-main-blue dark:text-main-pink text-xl duration-1000"><AiFillCaretRight /></span> <Description text={item[`firstul_${intl.locale}`]}/>  </li>
                        })}
                    </ul>
                </div>

                <div className="w-full sm:w-1/2 lg:w-1/5  space-y-8 max-lg:px-5 max-lg:mt-4">
                    {secondtitle.map((item, index) => {
                        return <MiniTitles mini={item[`secondtitle_${intl.locale}`]} key={index}/>
                    })}

                    <ul className="space-y-2">
                        {secondul.map((item, index) => {
                            return <li className="flex items-center" key={index}><span className="text-main-blue dark:text-main-pink text-xl duration-1000"><AiFillCaretRight /></span> <Description text={item[`secondul_${intl.locale}`]} />  </li>
                        })}
                    </ul>
                </div>

                <div className="w-full sm:w-1/2 lg:w-1/5  space-y-8 max-lg:px-5 max-lg:mt-4">
                    {thirdtitle.map((item, index) => {
                        return <MiniTitles mini={item[`thirdtitle_${intl.locale}`]} key={index}/>
                    })}

                    <ul className="space-y-2">
                        {thirdul.map((item, index) => {
                            return <li className="flex items-center" key={index}><span className="text-main-blue dark:text-main-pink text-xl duration-1000" >
                            <span className="">
                            {item.address} 

                            </span>
                            
                               </span> <Description text={item[`thirdul_${intl.locale}`]} />  </li>
                        })}
                    </ul>
                </div>
            </div>

            <div className="py-3 text-center sm:py-5 sm:mx-16">
                <Description text={'Copyright © 2023 epickify | Powered by epickify'} />
            </div>


        </>
    )
}