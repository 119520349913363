import React from "react";


export default function Description(props){
    return(
        <>
            <div className="text-base text-grey-400 text-[#7E8186]" >
                {props.text}
                
            </div>
        </>
    )
}