import React, { useRef } from "react";
import Hashtag from '../PropsComponents/hashtag';
import TitleColors from '../PropsComponents/titleColors';
import MoreButton from '../PropsComponents/moreButton';
import ForAbout from '../PropsComponents/forAbout'
import { HiMail } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillClockCircle } from "react-icons/ai"
import emailjs from "@emailjs/browser";
import { useIntl } from 'react-intl';




export default function HomeContact() {

    
    const intl = useIntl();
    
    const form = useRef();
    
    const placeholdername = intl.formatMessage({ id: 'first' });
    const placeholderlastname = intl.formatMessage({ id: 'last' });
    const placeholdermail = intl.formatMessage({ id: 'mail' });
    const placeholdersub = intl.formatMessage({ id: 'sub' });
    const placeholdercom = intl.formatMessage({ id: 'com' });



    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cchzbwp', 'template_i0isp8j', e.target, 'vWKrSQI4bjgX9Noov')
            .then((result) => {
                console.log(result);
                e.target.reset();
            }, (error) => {
                console.log(error.text);
            });
    };


    const hashtags = [{
        text_am: "Կապ", text_ru: "Свяжитесь с нами", text_en: "CONTACT US"
    }]


    const titles = [{
        black_en: "Drop Up A Message", black_ru: "Отправьте сообщение", black_am: "Թողնել հաղորդագրություն"
    }]

    const btn = [{
        btn_en: "Send message", btn_am: "Ուղարկել Նամակ", btn_ru: "Отправить Сообшение"
    }
    ]


    const aboutAddress = [
        {
            icon: <FaMapMarkerAlt />,
            minititle_am: 'Մեր Հասցեն',
            minititle_ru: 'Наша Локация',
            minititle_en: 'Our location',

            minitext_am: "321 Car World, 2d Breaking Str,",
            minitext_ru: "321 Car World, 2d Breaking Str,",
            minitext_en: '321 Car World, 2d Breaking Str,',

            minitext1_en: ' Newyork, USA 10002. ',
            minitext1_ru: ' Нью-Йорк, США 10002. ',
            minitext1_am: ' Նյու Յորք, ԱՄՆ 10002. ',
            i: 1
        },

        {
            icon: <HiMail />,
            minititle_en: 'Quick Contact',
            minititle_ru: 'Быстрый контакт',
            minititle_am: 'Արագ կապ',

            mail_en: 'Email: info@sellogic.com ',
            mail_ru: 'эл. почта: info@sellogic.com ',
            mail_am: 'Էլ․փոստ: info@sellogic.com ',

            minitext2_en: "Call Us: +12094728764",
            minitext2_ru: "Звоните: +12094728764",
            minitext2_am: "Զանգահարեք: +12094728764",
            i: 2
        },
        {
            icon: <AiFillClockCircle />,
            minititle_en: 'Opening Time',
            minititle_ru: 'Время открытия',
            minititle_am: 'Բացման ժամը',

            minitext_en: 'Mon-Fri: 9:30am - 21:00pm',
            minitext_ru: 'Пон-Суб: 9:30am - 21:00pm',
            minitext_am: 'Երկ-Ուրբ: 9:30am - 21:00pm',

            minitext3_en: 'Sat: 10:00am - 15:00pm',
            minitext3_ru: 'Суб: 10:00am - 15:00pm',
            minitext3_am: 'Ուրբ: 10:00am - 15:00pm',

            minitext4_en: 'On Public Holidays: Closed',
            minitext4_ru: 'В праздничные дни: закрыто',
            minitext4_am: 'Պետական տոներին: փակ',
            i: 3
        }
    ]

    return (
        <>
            <div id="ContactUs" className="flex flex-col justify-center mt-20 py-5 max-sm:px-10 lg:flex lg:flex-row">
                <div className="w-full md:px-24 lg:w-1/2 lg:px-10">
                    {
                        hashtags.map((item, index) => {
                            return <Hashtag text={item[`text_${intl.locale}`]} key={index}/>
                        })
                    }
                    {
                        titles.map((item, index) => {
                            return <TitleColors black={item[`black_${intl.locale}`]} blue={'.'} key={index}/>
                        })
                    }

                    <div className="lg:px-5 lg:space-y-5">
                        {
                            aboutAddress.map((item, index) => {
                                return <ForAbout
                                    icon={item.icon}
                                    minititle={item[`minititle_${intl.locale}`]}
                                    minitext={item[`minitext_${intl.locale}`]}
                                    minitext1={item[`minitext1_${intl.locale}`]}
                                    mail={item[`mail_${intl.locale}`]}
                                    minitext2={item[`minitext2_${intl.locale}`]}
                                    minitext3={item[`minitext3_${intl.locale}`]}
                                    minitext4={item[`minitext4_${intl.locale}`]}
                                    key={index}
                                />
                            })
                        }
                    </div>
                </div>


                <div className="w-full lg:w-1/2">

                    <div className="w-[90%] mx-auto rounded-3xl shadow-xl p-5]">
                        <form onSubmit={sendEmail} action="" className="w-full py-10 px-10 max-sm:flex-col flex flex-wrap justify-around">

                            <input required type="text" name="first_name" placeholder={placeholdername} className="mt-4 p-4 rounded-full border border-[#c2c8d4] w-[48%]  max-sm:w-full text-sm" />
                            <input required type="text" name="last_name" placeholder={placeholderlastname} className="mt-4 p-4 rounded-full border border-[#c2c8d4] w-[48%]  max-sm:w-full text-sm" />
                            <input required type="email" name="email" placeholder={placeholdermail} className="mt-4 p-4 rounded-full border border-[#c2c8d4] w-[48%]  max-sm:w-full text-sm" />
                            <input required type="text" name="subject" placeholder={placeholdersub} className="mt-4 p-4 rounded-full border border-[#c2c8d4] w-[48%]  max-sm:w-full text-sm" />

                            <textarea name="comment" placeholder={placeholdercom} id=" " className="mt-4 p-4 w-full rounded-3xl border border-[#c2c8d4] text-sm " cols="30" rows="5"></textarea>

                            <button type="submit" className="w-fit mx-auto py-6">
                                {
                                    btn.map((item, index) => {
                                        return <MoreButton btn={item[`btn_${intl.locale}`]} key={index}/>
                                    })
                                }
                            </button>
                        </form>
                    </div>


                </div>
            </div>
        </>
    )
}