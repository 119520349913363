import React from "react";
import Description from "./description";
import { BsFillStarFill } from "react-icons/bs"
import MiniTitles from "../PropsComponents/miniTitles";

export default function SliderReviews(props){
    return(
        <>
        
        <div className="space-y-5">
                <div>
                    <ul className="flex text-[#FEC14A] dark:text-main-pink space-x-1">
                        <li><BsFillStarFill /></li>
                        <li><BsFillStarFill /></li>
                        <li><BsFillStarFill /></li>
                        <li><BsFillStarFill /></li>
                        <li><BsFillStarFill /></li>
                    </ul>
                </div>
                <div className="text-right">
                    <MiniTitles mini={props.name}/>
                    <p className="text-main-blue font-monts text-[14px]">{props.designation}</p>
                </div>
                <div>
                    <Description text={props.desc} />
                </div>
            </div>
        
        </>
    )
}