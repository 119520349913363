import React from "react";
import Hashtag from "../PropsComponents/hashtag";
import TitleColors from "../PropsComponents/titleColors";
import NewsContent from "../PropsComponents/newsContent";
import Left from "../../assets/img/News/left.jpg";
import Center from "../../assets/img/News/center.jpg";
import Right from "../../assets/img/News/right.jpg";
import { useIntl } from 'react-intl';

export default function HomeNews() {

    const intl = useIntl();

    const news = [
        {
            imagenews: Left,
            newstitle_en: 'Who are the top crypto influencers?',
            newstitle_ru: 'Кто самые влиятельные Инфлюенсеры?',
            newstitle_am: 'Ովքե՞ր են աշխարհի լավագույն կրիպտո Ինֆլուենսերները:',

            newsdata_en: 'April 18, 2022',
            newsdata_ru: 'Апрель 18, 2022',
            newsdata_am: 'Ապրիլ 18, 2022',

            newstext_en: 'Who are the top crypto influencers? Lorem ipsum dolor sit amet,...',
            newstext_ru: 'Кто самые влиятельные криптовалюты? Очень важно набраться терпения...',
            newstext_am: 'Ովքե՞ր են լավագույնները: Շատ կարևոր է լինել համբերատար,...',

            newsbutton_en: 'READ MORE',
            newsbutton_ru: 'СМОТРЕТЬ БОЛЬШЕ',
            newsbutton_am: 'ԿԱՐԴԱԼ ԱՎԵԼԻՆ',
        },

        {
            imagenews: Center,

            newstitle_en: 'Your Guide to Crypto Influencer Marketing',
            newstitle_ru: 'Маркетинг крипто-инфлюенсеров',
            newstitle_am: 'Կրիպտո ինֆլուենսեր մարկետինգի ձեր ուղեցույցը',

            newsdata_en: 'April 18, 2022',
            newsdata_ru: 'Апрель 18, 2022',
            newsdata_am: 'Ապրիլ 18, 2022',

            newstext_en: 'Your Guide to Crypto Influencer Marketing Lorem ipsum dolor sit amet,...',
            newstext_ru: 'Ваш путеводитель по маркетингу крипто-инфлюенсеров Lorem ipsum dolor sit amet,...',
            newstext_am: 'Ձեր ուղեցույցը Հաճախորդների սպասարկման համար կարևոր է,...',



            newsbutton_en: 'READ MORE',
            newsbutton_ru: 'СМОТРЕТЬ БОЛЬШЕ',
            newsbutton_am: 'ԿԱՐԴԱԼ ԱՎԵԼԻՆ',
        },


        {
            imagenews: Right,
            
            newstitle_en: 'Influencers Taking over Social Media',
            newstitle_ru: 'Инфлюенсеры захватывают сети',
            newstitle_am: 'Սոցիալական մեդիան տիրող ազդեցություն ունեցողները',
            
            newsdata_en: 'April 18, 2022',
            newsdata_ru: 'Апрель 18, 2022',
            newsdata_am: 'Ապրիլ 18, 2022',
            
            newstext_en: 'Who are the top crypto influencers? Lorem ipsum dolor sit amet,...',
            newstext_ru: 'Кто самые влиятельные криптовалюты? Очень важно набраться терпения...',
            newstext_am: 'Ովքե՞ր են լավագույնները: Շատ կարևոր է լինել համբերատար,...',

            newsbutton_en: 'READ MORE',
            newsbutton_ru: 'СМОТРЕТЬ БОЛЬШЕ',
            newsbutton_am: 'ԿԱՐԴԱԼ ԱՎԵԼԻՆ',
        }
    ]

    const hashtags = [{
        text_am: "Մեր վերջին նորթությունները", text_ru: "Наши новости", text_en: "Our news"
    }]


    const titles = [{
        black_en: "See Our Latest & Popular News", black_ru: "Смотрите наши последние новости", black_am: "Ինչ են ասում մեր Հետևորդները"
    }]


    return (

        <>
            <div id="News" className=" w-full max-w-screen-xl mx-auto mt-36">

                <div className=" flex flex-col items-center">
                    {
                        hashtags.map((item, index) => {
                            return <Hashtag text={item[`text_${intl.locale}`]} key={index}/>
                        })
                    }
                    {
                        titles.map((item, index) => {
                            return <TitleColors black={item[`black_${intl.locale}`]} blue={'.'} key={index}/>
                        })
                    }
                </div>
                <div className="w-full flex flex-wrap mt-14">
                    {
                        news && news.map((item, index) => {
                            return <NewsContent imagenews={item.imagenews} newstitle={item[`newstitle_${intl.locale}`]} newsdata={item[`newsdata_${intl.locale}`]} newstext={item[`newstext_${intl.locale}`]} newsbutton={item[`newsbutton_${intl.locale}`]} key={index}/>
                        })

                    }

                </div>
            </div>

        </>
    )
}