import React from "react";
import CountUp from "react-countup";


export default function Views(props){
    return(
        <>
            <div className="font-bold text-center md:w-1/2 lg:w-1/4">
                <div className="text-6xl">
                   + <CountUp end={props.num} duration={3} />  <span className="text-main-blue dark:text-main-pink">{props.letter}</span>
                </div>
                <div className="mt-5 text-xl ">{props.desc}</div>
            </div>
        </>
    )
}