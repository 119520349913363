import React from "react";
import Hashtag from "../PropsComponents/hashtag";
import TitleColors from "../PropsComponents/titleColors";
import Description from "../PropsComponents/description";
import MoreButton from "../PropsComponents/moreButton";
import { AiFillPlayCircle } from "react-icons/ai"
import MainGirl from "../../assets/img/Main/homeGirl.png"
import HeartPic from "../../assets/img/heart.png"
import PlanePic from "../../assets/img/paperPlane.png"
import Views from "../PropsComponents/views";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";




export default function HomeFirst() {

    const intl = useIntl();

    const hashtags = [{
        text_am: "Հեյ, ես Ջոն Քարտերն եմ", text_ru: "ЭЙ, Я ДЖОН КАРТЕР", text_en: "HEY, I'M JOHN CARTER"
    }]

    const btn = [{
        btn_en: "Discover More" , btn_am: "Տեսնել Ավելին", btn_ru: "Посмотреть больше"
    }
    ]

    const desc = [{
        text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.", 
        text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
        text_am: "Հաճախորդը շատ կարևոր է, հաճախորդին հետևելու է հաճախորդը։ Ինչպես երկրի երկիրը, ողբը, ոչ էլ կապրալը, առյուծի բարձը:"
    }]

    const titles = [{
        black_en: "Beauty and Lifestyle Influencer", black_ru: "Инфлюенсер красоты и образа жизни", black_am: "Գեղեցկության և ապրելակերպի ինֆլուենսեր"
    }]

    const info = [
        { num: '78', letter_en: 'M', letter_ru: "М", letter_am: "Մլն", desc_am: "Դիտումներ", desc_ru: "Просмотры", desc_en: "Video Views" },
        { num: '11', letter_en: 'M', letter_ru: "М", letter_am: "Մլն", desc_en: "Followers", desc_ru: "Подписчики", desc_am: "Հետևորդներ" },
        { num: '49', letter_en: 'M', letter_ru: "М", letter_am: "Մլն", desc_en: "Likes", desc_ru: "Нравится", desc_am: "Հավանում են" },
        { num: '78', letter_en: 'M', letter_ru: "М", letter_am: "Մլն", desc_en: "Comments", desc_ru: "Комментарии", desc_am: "Մեկնաբանություններ" },
    ]


    


    return (
        <>


            <div className="flex flex-col-reverse justify-center items-center pb-10 sm:pb-20 lg:flex lg:flex-row px-5 mt-24" id="Home">

                <div className="w-full lg:w-1/2 mt-5">
                    <div>
                        {
                            hashtags.map((item, index) => {
                                return <Hashtag text={item[`text_${intl.locale}`]} key={index}/>
                            })
                        }

                    </div>

                    <div className="mt-5">
                        {
                            titles.map((item, index) => {
                                return <TitleColors black={item[`black_${intl.locale}`]} blue={'.'} key={index}/>
                            })
                        }
                    </div>

                    <div className="mt-8">
                        {
                            desc.map((item, index) => {
                                return <Description text={item[`text_${intl.locale}`]} key={index}/>
                            })
                        }
                    </div>

                    <div className="mt-9 sm:ml-5 flex max-sm:space-y-6 flex-col sm:flex-row items-center sm:space-x-7">
                        <div>
                        {
                            btn.map((item, index) => {
                                return <MoreButton btn={item[`btn_${intl.locale}`]} key={index}/>
                            })
                        }
                        </div>

                        <div className="text-6xl text-[#FEC14A] dark:text-main-blue hover:animate-beat">
                            <AiFillPlayCircle />
                        </div>
                        <div>
                            <h1 className="tracking-wider text-xl uppercase text-[#1f2c39]"><FormattedMessage id="question" /></h1>
                            
                        </div>
                    </div>

                </div>

                <div className="w-[300px] flex justify-center duration-500 md:flex md:justify-center md:w-[80%] lg:flex lg:w-1/2 relative lg:justify-center items-center">
                    <img src={PlanePic} alt="" className="z-20 absolute  top:0 right-0 sm:-right-14  md:top-24   md:right-0 lg:top-22 lg:right-0 max-sm:w-[60px] w-24 animate-plane" />

                    <img src={HeartPic} alt="" className="z-20 absolute top:0 left-0 sm:-left-14 md:top-24   md:left-0 lg:top-40 lg:left-0 w-[90px] sm:w-[130px] sm:h-[90px] animate-beat" />
                    <div className="w-[300px] md:w-[400px] lg:w-[500px] duration-500 girl_bg  rounded-b-full">
                        <img src={MainGirl} className="relative z-10" alt="" />
                    </div>
                </div>

            </div>



            <div className="mt-10 w-full md:flex md:flex-wrap justify-around">
                {
                    info.map((item, index) => {
                        return <Views num={item.num} letter={item[`letter_${intl.locale}`]} desc={item[`desc_${intl.locale}`]} key={index}/>
                    })
                }
            </div>

        </>


    )

}
