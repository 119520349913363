import React from "react";
import { useIntl } from "react-intl";
export default function MoreButton(props){
    const intl=useIntl();

    return(
        <>
            <div className={ intl.locale === 'am' || intl.locale === 'ru' ? 'px-10 sm:px-4 py-3 flex items-center justify-center dark:bg-main-pink bg-main-blue text-[19px] rounded-full text-white text-center uppercase font-medium shadow-3xl hover:cursor-pointer hover:animate-beat' : 'px-16 w-full sm:w-fit py-3 flex items-center justify-center dark:bg-main-pink bg-main-blue text-xl tracking-wide rounded-full text-white uppercase font-medium shadow-3xl hover:cursor-pointer hover:animate-beat' } >
                {props.btn} <span>{props.icon}</span>
            </div>
       
        </>
    )
}