import React from "react";
import { useIntl } from "react-intl";


export default function MiniTitles(props){
    const intl = useIntl();
    return(
        <>
            <div className={intl.locale === 'am'? "text-[23px] font-bold font-monts text-black"  : "text-2xl font-bold font-monts text-black"}>
                {props.mini}
            </div>

        </>
    )
}