import React from "react";
import MiniTitles from "./miniTitles";
import Description from "./description";


export default function ForAbout(props) {
    return (
        <>
            <div className="flex mt-5">

                <div className="h-10 w-10 px-2 flex border justify-center items-center rounded-xl text-white text-xl bg-[#FEC14A] dark:bg-main-pink" key={props.i}>
                    {props.icon}
                </div>

                <div className="px-3">
                    <h2 className="text-2xl font-bold">
                        <MiniTitles mini={props.minititle} />
                    </h2>
                    <div className="text-lg text-grey-400 text-[#7E8186]">
                        <Description text={props.minitext} />
                        
                    </div>
                    
                    <div>
                        <Description text={props.minitext1} />
                    </div>
                    <div>
                        <Description text={props.minitext2} />
                    </div>
                    <div>
                        <Description text={props.minitext3} />
                    </div>
                    <div>
                        <Description text={props.minitext4} />
                    </div>
                    <div>
                        <Description text={props.mail} />
                    </div>
                </div>
            </div>

        </>
    )
}