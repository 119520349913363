import React from "react";
import Header from "./pages/layouts/header";
import Home from "./pages/home";
import Footer from "./pages/layouts/footer";



function App() {
  return (
    <>
    <div className="max-w-screen-xl mx-auto"> 
      <Header />
      <Home />
      <Footer />
    </div>
    </>
  );
}

export default App;
