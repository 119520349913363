import React from "react";
import Hash from "../../assets/img/Hashtags/hashtag.png";
import HashDark from "../../assets/img/Hashtags/hashtagDark.png"


export default function Hashtag(props){
    return(
        <>
            <div className="bg-[#19c2c124]  dark:bg-[#dd56a924] uppercase  w-fit font-medium text-main-blue dark:text-main-pink text-[18px] flex items-center px-3 py-1 rounded-full">
                <div>
                    <img src={Hash} className="h-7 w-7 mx-2" alt="" />
                </div>

                <div>
                    <p>{props.text}</p>                                     
                </div>
            </div>
      
        </>
    )
}