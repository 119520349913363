import React from "react";
import Hashtag from "../PropsComponents/hashtag";
import TitleColors from "../PropsComponents/titleColors";
import Services from "../PropsComponents/services";
import SImg1 from "../../assets/img/Services/1.png";
import SImg2 from "../../assets/img/Services/2.png";
import SImg3 from "../../assets/img/Services/3.png";
import SImg4 from "../../assets/img/Services/4.png";
import SImg5 from "../../assets/img/Services/5.png";
import SImg6 from "../../assets/img/Services/6.png";
import { useIntl } from "react-intl";


export default function HomeServices() {

    const intl = useIntl();

    const servicescont = [
        { serviceimg: SImg1, BG: 'bg-[#19C2C1]', 
        mini_en: "Talent Management", 
        mini_ru: "Управление Талантами", 
        mini_am: "Տաղանդների Կառավարում", 
        text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
        text_am: "Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։", },

        { serviceimg: SImg2, BG: 'bg-[#FEC14A]  dark:bg-main-pink', 
        mini_en: "Nano Influencers", 
        mini_ru: "Нано Инфлюенсеры", 
        mini_am: "Նանո Ինֆլուենսերներ", 
        text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
        text_am: "Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։", },

        { serviceimg: SImg3, BG: 'bg-[#19C2C1]', 
        mini_en: "Celebrity Influencers", 
        mini_ru: "Знаменитости", 
        mini_am: "Հայտնի Ինֆլուենսերներ", 
        text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
        text_am: "Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։", },


        { serviceimg: SImg4, BG: 'bg-[#FEC14A]  dark:bg-main-pink', 
        mini_en: "Hero Influencers", 
        mini_ru: "Герои Инфлюенсеров", 
        mini_am: "Հերոս Ինֆլուենսերներ", 
        text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
        text_am: "Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։",},


        { serviceimg: SImg5, BG: 'bg-[#19C2C1]', 
        mini_en: "Macro Influencers", 
        mini_ru: "Макро Инфлюенсеры", 
        mini_am: "Մակրո Ինֆլուենսերներ", 
        text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
        text_am: "Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։",},


        { serviceimg: SImg6, BG: 'bg-[#FEC14A]  dark:bg-main-pink', 
        mini_en: "Luxury Influencers", 
        mini_ru: "Роскошные Инфлюенсеры", 
        mini_am: "Շքեղ Ինֆլուենսերներ", 
        text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
        text_am: "Կարևոր է հոգ տանել հիվանդի մասին, հետևել հիվանդին, բայց դա տեղի կունենա այնպիսի ժամանակ, որ աշխատանքն ու ցավը շատ լինեն։",},
    ]

    const hashtags = [{
        text_am: "ինչ ենք մենք անում", text_ru: "Что мы делаем", text_en: "What we do"
    }]

    const titles = [{
        black_en: "My Services", black_ru: "Мои услуги", black_am: "Իմ Գործառույթները"
    }]




    return (
        <>

            <div id="Services" className="mt-10">
                <div className="flex flex-col justify-center items-center tracking-widest">
                    {
                        hashtags.map((item, index) => {
                            return <Hashtag text={item[`text_${intl.locale}`]} key={index}/>
                        })
                    }
                </div>
                <div className="flex flex-col justify-center items-center">
                    {
                        titles.map((item, index) => {
                            return <TitleColors black={item[`black_${intl.locale}`]} blue={'.'} key={index}/>
                        })
                    }
                </div>
            </div>


            <div className="mt-10 flex flex-wrap">

                {servicescont.map((item, index) => {
                    return (
                        <Services
                            serviceimg={item.serviceimg}
                            BG={item.BG}
                            mini={item[`mini_${intl.locale}`]}
                            text={item[`text_${intl.locale}`]}
                            key={index}
                        />
                    )
                })}
            </div>

        </>
    )
}