import React from 'react'
import MainGirl from '../../assets/img/Main/maingirl2.jpg'
import Analytics from '../../assets/img/Main/analytics.jpg'
import Hashtag from '../PropsComponents/hashtag'
import TitleColors from '../PropsComponents/titleColors'
import Description from '../PropsComponents/description'
import YellowHeart from '../../assets/img/yellowHeart.png'
import ForAbout from '../PropsComponents/forAbout'
import MoreButton from '../PropsComponents/moreButton'
import { FaComments } from 'react-icons/fa'
import { FaChartPie } from 'react-icons/fa'
import { FaTasks } from 'react-icons/fa'
import { useIntl } from 'react-intl'
export default function HomeAbout() {

  const intl = useIntl();

  const hashtags = [{
    text_am: "Մեր Մասին", text_ru: "О Нас", text_en: "About"
  }]


  const titles = [{
    black_en: "Beauty and Lifestyle Influencer", black_ru: "Инфлюенсер красоты и образа жизни", black_am: "Գեղեցկության և ապրելակերպի ինֆլուենսեր"
  }]

  const btn = [{
    btn_en: "Discover More", btn_am: "Տեսնել Ավելին", btn_ru: "Посмотреть больше"
  }
  ]

  const desc = [{
    text_en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    text_ru: "Клиент очень важен, за клиентом последует клиент. Как земля земли, траур ни телесный земли, подушка льва.",
    text_am: "Հաճախորդը շատ կարևոր է, հաճախորդին հետևելու է հաճախորդը։ Ինչպես երկրի երկիրը, ողբը, ոչ էլ կապրալը, առյուծի բարձը:"
  }]

  const aboutinfoo = [
    {
      icon: <FaComments />,
      minititle_am: 'Հաղորդակցություն',
      minititle_ru: 'Коммуникация',
      minititle_en: 'Communication',
      minitext_am: "Հաճախորդի համար շատ կարևոր է հետևել հաճախորդի թրեյնինգին, բայց դա այն է, ինչ տեղի է ունենում միաժամանակ։",
      minitext_ru: "Для клиента очень важно следовать обучению клиента, но это то, что происходит одновременно.",
      minitext_en:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
      i: 1
    },
    {
      icon: <FaChartPie />,
      minititle_am: 'ստեղծագործական և խնդիրների լուծում',
      minititle_ru: 'Креатив и решение проблем',
      minititle_en: 'Creativity and problem-solving',
      minitext_am: "Հաճախորդի համար շատ կարևոր է հետևել հաճախորդի թրեյնինգին, բայց դա այն է, ինչ տեղի է ունենում միաժամանակ։",
      minitext_ru: "Для клиента очень важно следовать обучению клиента, но это то, что происходит одновременно.",
      minitext_en:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
      i: 2
    },
    {
      icon: <FaTasks />,
      minititle_am: 'Ուշադրություն մանրուքներին',
      minititle_ru: 'Внимание к детали',
      minititle_en: 'Attention to Detail',
      minitext_am: "Հաճախորդի համար շատ կարևոր է հետևել հաճախորդի թրեյնինգին, բայց դա այն է, ինչ տեղի է ունենում միաժամանակ։",
      minitext_ru: "Для клиента очень важно следовать обучению клиента, но это то, что происходит одновременно.",
      minitext_en:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
      i: 3
    }
  ]

  return (
    <>
      <div
        id='About'
        className='flex flex-col-reverse justify-center items-center mt-20 py-10 px-5 lg:flex lg:flex-row '
      >
        <div className='relative w-full py-10 lg:w-1/2 lg:flex lg:justify-center object-cover '>
          <div className='w-full sm:w-[90%] border h-[650px] rounded-[20px] overflow-hidden  '>
            <img src={MainGirl} className='object-cover w-full h-full' alt='' />
          </div>
          <div className='absolute w-48 sm:h-44 sm:w-60 border border-gray-300 rounded-[20px] overflow-hidden bottom-5 -right-3 shadow-2xl hover:bottom-8 duration-500'>
            <img src={Analytics} className='h-full w-full shadow-2xl' alt='' />
          </div>
        </div>

        <div className='w-full lg:w-1/2'>
          <div className='w-full lg:w-[90%] mx-auto h-full '>
            <div className='relative mt-16 px-10 '>
              {
                hashtags.map((item, index) => {
                  return <Hashtag text={item[`text_${intl.locale}`]} key={index} />
                })
              }
              <div className='mt-4'>
                {
                  titles.map((item, index) => {
                    return <TitleColors black={item[`black_${intl.locale}`]} blue={'.'} key={index} />
                  })
                }
              </div>
              <div className='absolute h-8 w-20 -top-10 right-0 animate-beat '>
                <img src={YellowHeart} alt='' />
              </div>
            </div>

            <div className='mt-5 ml-7'>
              {
                desc.map((item, index) => {
                  return <Description text={item[`text_${intl.locale}`]} key={index} />
                })
              }

            </div>

            <div className=' ml-7 mt-2'>
              {aboutinfoo.map((item, index) => {
                return (
                  <ForAbout
                    icon={item.icon}
                    minititle={item[`minititle_${intl.locale}`]}
                    minitext={item[`minitext_${intl.locale}`]}
                    key={index}
                  />
                )
              })}
            </div>

            <div className='mt-10 max-sm:flex max-sm:justify-center sm:ml-7'>
              {
                btn.map((item, index) => {
                  return <MoreButton btn={item[`btn_${intl.locale}`]} key={index} />
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
