import React, { useState } from "react";
import Logo from "../../assets/img/Logo/Logo.png"
import { Link } from "react-scroll";
import { FaBars } from "@react-icons/all-files/fa/FaBars";
import ThemeSwitch from "../Components/ThemeSwitch";
import Lang from "../Multilang/SetLang";
import { FormattedMessage } from "react-intl";




export default function Header() {

    const [Open, setOpen] = useState(true)


    return (
        <>
            <div className="fixed top-0 w-full left-0 z-[100] bg-white pb-2">
                <div className="fixed z-10 left-8 top-24">
                    <Lang />
                </div>
                <div className="max-w-screen-xl mt-5 mx-auto flex justify-between text-xl max-xl:px-6">
                    <div>
                        <Link activeClass="active" to="Home" spy={true} smooth={true} offset={-100} duration={500}>
                            <img src={Logo} className="img-fluid cursor-pointer lg:w-[15vw] sm:w[8vw] h-16 max-lg:h-10" alt="" />
                        </Link>
                    </div>


                    <div className="hidden lg:block ">
                        <ul className="flex space-x-7 items-center font-semibold">
                            <li className="hover:text-main-blue dark:hover:text-main-pink hover:cursor-pointer">
                                <Link activeClass="active" to="Home" spy={true} smooth={true} offset={-100} duration={500}>
                                    <FormattedMessage id="navbar_home" />
                                </Link>
                            </li>
                            <li className="hover:text-main-blue hover:dark:text-main-pink hover:cursor-pointer duration-100">
                                <Link activeClass="active" to="About" spy={true} smooth={true} offset={-50} duration={500}>
                                    <FormattedMessage id="navbar_about" />
                                </Link>
                            </li>
                            <li className="hover:text-main-blue hover:dark:text-main-pink hover:cursor-pointer duration-100">

                                <Link activeClass="active" to="Services" spy={true} smooth={true} offset={-90} duration={500}>
                                    <FormattedMessage id="navbar_services" />
                                </Link>
                            </li>
                            <li className="hover:text-main-blue hover:dark:text-main-pink hover:cursor-pointer duration-100">
                                <Link activeClass="active" to="Videos" spy={true} smooth={true} offset={-100} duration={500}>
                                    <FormattedMessage id="navbar_videos" />
                                </Link>
                            </li>
                            <li className="hover:text-main-blue hover:dark:text-main-pink hover:cursor-pointer duration-100">
                                <Link activeClass="active" to="Reviews" spy={true} smooth={true} offset={-80} duration={500}>
                                    <FormattedMessage id="navbar_reviews" />
                                </Link>
                            </li>
                            <li className="hover:text-main-blue hover:dark:text-main-pink hover:cursor-pointer duration-100">
                                <Link activeClass="active" to="News" spy={true} smooth={true} offset={-100} duration={500}>
                                    <FormattedMessage id="navbar_news" />
                                </Link>
                            </li>

                            <li className="bg-main-blue dark:bg-main-pink text-white hover:cursor-pointer font-bold py-2 px-9 rounded-full shadow-3xl">
                                <Link activeClass="" to="ContactUs" spy={true} smooth={true} offset={-80} duration={500}>
                                    <FormattedMessage id="navbar_contact" />
                                </Link>
                            </li>
                        </ul>

                    </div>

                    <div className="lg:hidden flex">
                        <button onClick={() => { setOpen(!Open) }} className="text-main-blue dark:text-main-pink text-2xl font-bold">
                            <FaBars />
                        </button>
                    </div>




                </div>


                <div className={Open ? 'h-0 overflow-hidden z-50 w-full absolute transition-all duration-300' : 'h-72 z-50  bg-white transition-all duration-300 w-full  overflow-hidden absolute lg:hidden'}>
                    <ul className="font-semibold ">
                        <li className="p-2 hover:bg-main-blue dark:hover:bg-main-pink dark:hover:text-white hover:cursor-pointer hover:text-white">
                            <Link activeClass="active" to="Home" spy={true} smooth={true} offset={-100} duration={500}>
                                <FormattedMessage id="navbar_home" />
                            </Link>
                        </li>
                        <li className="p-2 hover:bg-main-blue dark:hover:bg-main-pink dark:hover:text-white hover:cursor-pointer hover:text-white">
                            <Link activeClass="active" to="About" spy={true} smooth={true} offset={-50} duration={500}>
                                <FormattedMessage id="navbar_about" />
                            </Link>
                        </li>
                        <li className="p-2 hover:bg-main-blue dark:hover:bg-main-pink dark:hover:text-white hover:cursor-pointer hover:text-white">

                            <Link activeClass="active" to="Services" spy={true} smooth={true} offset={-80} duration={500}>
                                <FormattedMessage id="navbar_services" />
                            </Link>
                        </li>
                        <li className="p-2 hover:bg-main-blue dark:hover:bg-main-pink dark:hover:text-white hover:cursor-pointer hover:text-white">
                            <Link activeClass="active" to="Videos" spy={true} smooth={true} offset={-80} duration={500}>
                                <FormattedMessage id="navbar_videos" />
                            </Link>
                        </li>
                        <li className="p-2 hover:bg-main-blue dark:hover:bg-main-pink dark:hover:text-white hover:cursor-pointer hover:text-white">
                            <Link activeClass="active" to="Reviews" spy={true} smooth={true} offset={-80} duration={500}>
                                <FormattedMessage id="navbar_reviews" />
                            </Link>
                        </li>
                        <li className="p-2 hover:bg-main-blue dark:hover:bg-main-pink dark:hover:text-white hover:cursor-pointer hover:text-white">
                            <Link activeClass="active" to="News" spy={true} smooth={true} offset={-80} duration={500}>
                                <FormattedMessage id="navbar_news" />
                            </Link>
                        </li>
                        <li className="p-2 hover:bg-main-blue dark:hover:bg-main-pink dark:hover:text-white hover:cursor-pointer hover:text-white">
                            <Link activeClass="" to="ContactUs" spy={true} smooth={true} offset={-80} duration={500}>
                                <FormattedMessage id="navbar_contact" />
                            </Link>
                        </li>
                        <li className="">
                            <ThemeSwitch />
                        </li>

                    </ul>

                </div>
            </div>
        </>
    )
}