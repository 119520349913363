import React from "react";
import MiniTitles from "../PropsComponents/miniTitles";
import Description from "../PropsComponents/description";

export default function  Services(props){




    return(
        <>
        
        <div className="group w-full lg:w-1/3 mt-4 hover:animate-service  " >
            <div className="w-[90%] shadow-2xl py-12 px-7 mx-auto border border-[#E9E9E9] rounded-3xl space-y-3">
                <div  className={` ${props.BG}  w-[80px] f-[80px] p-[15px] z-50 rounded-3xl group-hover:animate-service delay-500`}>
                    <img src={props.serviceimg} alt="" />
                </div>
                
                <div>
                <MiniTitles mini={props.mini}/>
                </div>
                
                <div>
                    <Description text={props.text}/>
                </div>
                </div>
            </div>
        </>
    )


} 